import { stripRichTextWrapperTag } from '../../utils/html';
import { adapter as imageAdapter } from '../../components/cloud-image/utils';
import { routerLinks, marketLinks } from '../../utils/fetch-links';
import * as prismic from '@prismicio/client';

const getItems = async (type, market, $prismic) => {
  const articles = await $prismic.client.get({
    filters: [
      prismic.filter.at('document.type', type),
      ...(market.id ? [prismic.filter.at(`my.${type}.market`, market.id)] : []),
    ],
    orderings: [
      {
        field: `my.${type}.date`,
        direction: 'desc',
      },
    ],
    pageSize: 4,
    fetchLinks: [...routerLinks, ...marketLinks],
  });

  return articles.results.map((result) => ({
    edito_carousel_card: { ...result, link_type: 'Document' },
  }));
};

export const cardsAdapter = (cards, i18n, $enhancedLinkSerializer) => {
  return cards.map(({ edito_carousel_card }) => {
    const { data, type: cardType } = edito_carousel_card;
    const marketTitle = data.market?.data?.title || '';
    const date =
      (data.date &&
        new Intl.DateTimeFormat(i18n.locale, {
          month: '2-digit',
          year: 'numeric',
        }).format(new Date(data.date))) ||
      null;

    return {
      title: stripRichTextWrapperTag(data.title),
      subtitle: marketTitle,
      date,
      type: cardType,
      image:
        cardType === 'article'
          ? imageAdapter(data.image, data.image_alt)
          : null,
      description:
        cardType === 'guide' ? stripRichTextWrapperTag(data.excerpt) : null,
      link: {
        ...$enhancedLinkSerializer(edito_carousel_card),
        label: i18n.t('cta.en-savoir-plus'),
      },
    };
  });
};

export default async (
  { primary, items },
  pageData,
  { $i18n, $prismic, $enhancedLinkSerializer }
) => {
  const type = primary.edito_carousel_type ? 'guide' : 'article';
  const market = primary.edito_carousel_market;
  const filteredItems = items.filter(
    ({ edito_carousel_card }) => edito_carousel_card.data
  );

  let articles = [];

  if (filteredItems.length > 0) {
    articles = filteredItems;
  } else if (type && market) {
    articles = await getItems(type, market, $prismic);
  }

  return {
    featured: primary.edito_carousel_featured,
    title: stripRichTextWrapperTag(primary.edito_carousel_title),
    link: $enhancedLinkSerializer(primary.edito_carousel_link),
    linkLabel: primary.edito_carousel_link_label,
    withCarousel: primary.edito_carousel_active,
    cards: cardsAdapter(articles, $i18n, $enhancedLinkSerializer),
  };
};
